import { memo } from "react";

import NextLinkOrigin from "next/link";

/**
 * Wrapper over {@link NextLink} component.
 */
export const NextLink = memo(({ children, href, ...props }) => (
  <NextLinkOrigin href={href} passHref>
    <a {...props}>{children}</a>
  </NextLinkOrigin>
));
